<template>
  <div id="bacteriaSterilization">
    <el-dialog
      :title="bacteriaSterilizationFormTitle"
      width="680px"
      :visible.sync="bacteriaSterilizationDialogVisible"
      :close-on-click-modal="false"
      @close="bacteriaSterilizationDialogClose"
    >
    <div id="pdfDom">
      <el-form
        ref="bacteriaSterilizationFormRef"
        :model="bacteriaSterilizationForm"
        :rules="bacteriaSterilizationFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="灭菌时间" prop="sterilizationDate">
              <el-date-picker
                v-model="bacteriaSterilizationForm.sterilizationDate"
                placeholder="请选择灭菌时间"
                value-format="yyyy-MM-dd"
                :disabled="bacteriaSterilizationFormTitle !== '新增实验中废弃的带菌物品灭菌处理记录'
                  && bacteriaSterilizationFormTitle !== '修改实验中废弃的带菌物品灭菌处理记录'
                  && bacteriaSterilizationFormTitle !== '实验中废弃的带菌物品灭菌处理记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="灭菌处理内容" prop="sterilizationContent">
              <el-input
                v-model="bacteriaSterilizationForm.sterilizationContent"
                placeholder="请输入灭菌处理内容"
                clearable
                :disabled="bacteriaSterilizationFormTitle !== '新增实验中废弃的带菌物品灭菌处理记录'
                  && bacteriaSterilizationFormTitle !== '修改实验中废弃的带菌物品灭菌处理记录'
                  && bacteriaSterilizationFormTitle !== '实验中废弃的带菌物品灭菌处理记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="灭菌方法" prop="sterilizationMethod">
              <el-input
                v-model="bacteriaSterilizationForm.sterilizationMethod"
                placeholder="请输入灭菌方法"
                clearable
                :disabled="bacteriaSterilizationFormTitle !== '新增实验中废弃的带菌物品灭菌处理记录'
                  && bacteriaSterilizationFormTitle !== '修改实验中废弃的带菌物品灭菌处理记录'
                  && bacteriaSterilizationFormTitle !== '实验中废弃的带菌物品灭菌处理记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注" prop="remarks">
              <el-input
                v-model="bacteriaSterilizationForm.remarks"
                placeholder="请输入备注"
                clearable
                :disabled="bacteriaSterilizationFormTitle !== '新增实验中废弃的带菌物品灭菌处理记录'
                  && bacteriaSterilizationFormTitle !== '修改实验中废弃的带菌物品灭菌处理记录'
                  && bacteriaSterilizationFormTitle !== '实验中废弃的带菌物品灭菌处理记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="监督结果" prop="monitorResult">
              <el-radio-group
                v-model="bacteriaSterilizationForm.monitorResult"
                :disabled="bacteriaSterilizationFormTitle !== '监督实验中废弃的带菌物品灭菌处理记录'
                  && bacteriaSterilizationFormTitle !== '实验中废弃的带菌物品灭菌处理记录详情'"
              >
                <el-radio :label="1">
                  通过
                </el-radio>
                <el-radio :label="2">
                  不通过
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
      <div slot="footer">
        <el-button @click="bacteriaSterilizationDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="bacteriaSterilizationFormSubmit">
          确 定
        </el-button>
        <el-button
          v-if="bacteriaSterilizationFormTitle === '实验中废弃的带菌物品灭菌处理记录详情'"
          type="primary"
          @click="getPdf"
        >
          下 载 PDF
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="灭菌时间">
        <el-date-picker v-model="searchForm.sterilizationDate" placeholder="请选择灭菌时间" value-format="yyyy-MM-dd" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button
          v-if="checkPermission(['BACTERIA_STERILIZATION_ADD'])"
          type="primary"
          icon="el-icon-plus"
          @click="handleAdd"
        >
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="bacteriaSterilizationPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :row-class-name="tableRowClassName"
      :height="getTableHeight()"
      highlight-current-row
    >
      <el-table-column label="灭菌时间">
        <template slot-scope="scope">
          <span v-if="scope.row.sterilizationDate">{{ scope.row.sterilizationDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="sterilizationContent" label="灭菌处理内容" />
      <el-table-column prop="sterilizationMethod" label="灭菌方法" />
      <el-table-column prop="remarks" label="备注" />
      <el-table-column prop="operator" label="操作人" />
      <el-table-column prop="supervisor" label="监督人" />
      <el-table-column label="监督结果">
        <template slot-scope="scope">
          <span v-if="scope.row.monitorResult === 1">通过</span>
          <span v-if="scope.row.monitorResult === 2">不通过</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            v-if="checkPermission(['BACTERIA_STERILIZATION_DELETE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            v-if="checkPermission(['BACTERIA_STERILIZATION_UPDATE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            v-if="checkPermission(['BACTERIA_STERILIZATION_SUPERVISE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleSupervise(scope.$index, scope.row)"
          >
            监督
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="bacteriaSterilizationPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addBacteriaSterilization,
  deleteBacteriaSterilization,
  updateBacteriaSterilization,
  selectBacteriaSterilizationInfo,
  selectBacteriaSterilizationList,
  superviseBacteriaSterilization
} from '@/api/quality/bacteriaSterilization'
import html2PDF from "jspdf-html2canvas";
import { Loading } from "element-ui";

export default {
  data () {
    return {
      bacteriaSterilizationDialogVisible: false,
      bacteriaSterilizationFormTitle: '',
      bacteriaSterilizationForm: {
        id: '',
        sterilizationDate: '',
        sterilizationContent: '',
        sterilizationMethod: '',
        remarks: '',
        monitorResult: '',
        status: '',
        taskId: ''
      },
      bacteriaSterilizationFormRules: {
        sterilizationDate: [{ required: true, message: '灭菌时间不能为空', trigger: ['blur', 'change']}]
      },
      bacteriaSterilizationPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        sterilizationDate: ''
      }
    }
  },
  created () {
    selectBacteriaSterilizationList(this.searchForm).then(res => {
      this.bacteriaSterilizationPage = res
    })
  },
  methods: {
    bacteriaSterilizationDialogClose () {
      this.$refs.bacteriaSterilizationFormRef.resetFields()
    },
    bacteriaSterilizationFormSubmit () {
      if (this.bacteriaSterilizationFormTitle === '实验中废弃的带菌物品灭菌处理记录') {
        this.bacteriaSterilizationDialogVisible = false
        return
      }
      this.$refs.bacteriaSterilizationFormRef.validate(async valid => {
        if (valid) {
          if (this.bacteriaSterilizationFormTitle === '新增实验中废弃的带菌物品灭菌处理记录') {
            this.bacteriaSterilizationForm.id = ''
            this.bacteriaSterilizationForm.status = 1
            await addBacteriaSterilization(this.bacteriaSterilizationForm)
          } else if (this.bacteriaSterilizationFormTitle === '修改实验中废弃的带菌物品灭菌处理记录') {
            await updateBacteriaSterilization(this.bacteriaSterilizationForm)
          } else if (this.bacteriaSterilizationFormTitle === '监督实验中废弃的带菌物品灭菌处理记录') {
            this.bacteriaSterilizationForm.status = 2
            await superviseBacteriaSterilization(this.bacteriaSterilizationForm)
          }
          this.bacteriaSterilizationPage = await selectBacteriaSterilizationList(this.searchForm)
          this.bacteriaSterilizationDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.bacteriaSterilizationFormTitle = '新增实验中废弃的带菌物品灭菌处理记录'
      this.bacteriaSterilizationDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteBacteriaSterilization(row.id)
        if (this.bacteriaSterilizationPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.bacteriaSterilizationPage = await selectBacteriaSterilizationList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.bacteriaSterilizationFormTitle = '修改实验中废弃的带菌物品灭菌处理记录'
      this.bacteriaSterilizationDialogVisible = true
      this.selectBacteriaSterilizationById(row.id)
    },
    handleSupervise (index, row) {
      this.bacteriaSterilizationFormTitle = '监督实验中废弃的带菌物品灭菌处理记录'
      this.bacteriaSterilizationDialogVisible = true
      this.selectBacteriaSterilizationById(row.id)
    },
    handleInfo (index, row) {
      this.bacteriaSterilizationFormTitle = '实验中废弃的带菌物品灭菌处理记录详情'
      this.bacteriaSterilizationDialogVisible = true
      this.selectBacteriaSterilizationById(row.id)
    },
    selectBacteriaSterilizationById (id) {
      selectBacteriaSterilizationInfo(id).then(res => {
        this.bacteriaSterilizationForm.id = res.id
        this.bacteriaSterilizationForm.sterilizationDate = res.sterilizationDate
        this.bacteriaSterilizationForm.sterilizationContent = res.sterilizationContent
        this.bacteriaSterilizationForm.sterilizationMethod = res.sterilizationMethod
        this.bacteriaSterilizationForm.remarks = res.remarks
        this.bacteriaSterilizationForm.monitorResult = res.monitorResult
        this.bacteriaSterilizationForm.status = res.status
        this.bacteriaSterilizationForm.taskId = res.taskId
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectBacteriaSterilizationList(this.searchForm).then(res => {
        this.bacteriaSterilizationPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectBacteriaSterilizationList(this.searchForm).then(res => {
        this.bacteriaSterilizationPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectBacteriaSterilizationList(this.searchForm).then(res => {
        this.bacteriaSterilizationPage = res
      })
    },
    tableRowClassName ({ row, rowIndex }) {
      if (this.checkPermission(['BACTERIA_STERILIZATION_SUPERVISE']) && row.status === 1) {
        return 'review'
      }
      return ''
    },
    getPdf () {
      let loading = ''
      let dom = document.getElementById('pdfDom')
      html2PDF(dom, {
        jsPDF: {
          unit: 'px',
          format: 'a4'
        },
        html2canvas: {
          imageTimeout: 15000,
          logging: true,
          useCORS: true
        },
        imageType: 'image/jpeg',
        imageQuality: 1,
        margin: {
          top: 20,
          right: 20,
          bottom: 0,
          left: 0
        },
        output: `实验中废弃的带菌物品灭菌处理记录${this.bacteriaSterilizationForm.sterilizationDate.substring(0, 10)}.pdf`,
        init: function () {
          loading = Loading.service({
            lock: true,
            text: 'pdf加载中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          })
        },
        success: function (pdf) {
          pdf.save(this.output)
          loading.close()
        }
      })
    }
  }
}
</script>

<style>
</style>
