import axios from '@/common/axios'
import qs from 'qs'

export function addBacteriaSterilization (data) {
  return axios({
    method: 'post',
    url: '/quality/bacteriaSterilization/add',
    data: qs.stringify(data)
  })
}

export function deleteBacteriaSterilization (id) {
  return axios({
    method: 'delete',
    url: '/quality/bacteriaSterilization/delete/' + id
  })
}

export function updateBacteriaSterilization (data) {
  return axios({
    method: 'put',
    url: '/quality/bacteriaSterilization/update',
    data: qs.stringify(data)
  })
}

export function selectBacteriaSterilizationInfo (id) {
  return axios({
    method: 'get',
    url: '/quality/bacteriaSterilization/info/' + id
  })
}

export function selectBacteriaSterilizationList (query) {
  return axios({
    method: 'get',
    url: '/quality/bacteriaSterilization/list',
    params: query
  })
}

export function superviseBacteriaSterilization (data) {
  return axios({
    method: 'put',
    url: '/quality/bacteriaSterilization/updateSupervise',
    data: qs.stringify(data)
  })
}
